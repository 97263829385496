import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import OutreachContentPreferencesSection
  from '../../../pages/outreach-content-preferences/outreach-content-preferences-section/outreach-content-preferences-section.component';
import NumberInputItem from '../input-items/number-input-item/number-input-item.component';
import { OutreachContentPreferencesFormikKeys } from '../../../pages/outreach-content-preferences/outreach-content-preferences.enums';
import SwitchInputItem from '../input-items/switch-input-item/switch-input-item.component';
import { useGetEmailSenderSelector } from '../../../../../store/rtk-query/apis/outreach/selectors/get-email-sender.selector';
import { EmailCredentialsTypeEnum } from '../../../../../enums/email-credentials-type.enum';
import { MoreOptionsProps } from './more-options';
import { Container, IncludeEmailSignatureWrapper, TrackEmailOpensWrapper } from './more-options.styled';
import EnableReadPermissionBanner from './enable-read-permission-banner/enable-read-permission-banner.component';
import TrackEmailOpensBanner from './track-email-opens-banner/track-email-opens-banner.component';

const MoreOptions = ({
  positionId,
  messageCharactersLength,
  includeEmailSignature,
  includeEmailTracking,
  onChangeHandler,
}: MoreOptionsProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: emailSender } = useGetEmailSenderSelector();
  const readPermissionsGranted = emailSender?.readPermissionsGranted;

  const onMessageCharactersLengthChange = (updatedLength: number) => {
    let value: number | undefined = updatedLength;

    if (updatedLength === 0) {
      value = undefined;
    }

    onChangeHandler(OutreachContentPreferencesFormikKeys.MessageCharactersLength, value);
  };

  return (
    <OutreachContentPreferencesSection
      title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.TITLE) }
    >
      <Container>
        <NumberInputItem
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.TITLE) }
          description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.DESCRIPTION) }
          placeholder={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.PLACEHOLDER) }
          inputLabel={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.MESSAGE_LENGTH.INPUT_LABEL) }
          value={ messageCharactersLength }
          min={ 0 }
          max={ 500 }
          changeHandler={ onMessageCharactersLengthChange }
        />
        { emailSender?.credentials?.type === EmailCredentialsTypeEnum.MicrosoftOauthRefreshToken && (
          <IncludeEmailSignatureWrapper>
            <SwitchInputItem
              title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.TITLE) }
              description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.INCLUDE_EMAIL_SIGNATURE.DESCRIPTION) }
              value={ includeEmailSignature }
              changeHandler={ (value) => onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailSignature, value) }
              disabled={ !readPermissionsGranted }
            />
            { !readPermissionsGranted && (
              <EnableReadPermissionBanner positionId={ positionId } />
            ) }
          </IncludeEmailSignatureWrapper>
        ) }
        <TrackEmailOpensWrapper>
          <SwitchInputItem
            title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.TITLE) }
            description={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MORE_OPTIONS.INPUTS.TRACK_EMAIL_OPENS.DESCRIPTION) }
            value={ includeEmailTracking }
            changeHandler={ (value) => onChangeHandler(OutreachContentPreferencesFormikKeys.IncludeEmailTracking, value) }
          />
          <TrackEmailOpensBanner />
        </TrackEmailOpensWrapper>
      </Container>
    </OutreachContentPreferencesSection>
  );
};

export default MoreOptions;
