/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { OutreachInitializationButton } from '../../../dialogs/outreach-initialization-dialog/outreach-initialization-dialog.styled';
import STRING_KEYS from '../../../../language/keys';
import { GoogleLoginProps } from './google-login';
import { GoogleLogo } from './google-login.styled';

const GOOGLE_LOGIN_FLOW = 'auth-code';
const GOOGLE_LOGIN_SCOPE_SEND_PERMISSIONS = 'https://www.googleapis.com/auth/gmail.send';
const GOOGLE_LOGIN_SCOPE_MODIFY_PERMISSIONS = 'https://www.googleapis.com/auth/gmail.modify';

export const GoogleLogin = ({ successHandler, errorHandler, readPermissionsApproved }: GoogleLoginProps): JSX.Element | null => {
  const { t: translate } = useTranslation();

  const googleLogin = useGoogleLogin({
    // todo: uncomment once google verification process is done
    // scope: readPermissionsApproved ? GOOGLE_LOGIN_SCOPE_MODIFY_PERMISSIONS : GOOGLE_LOGIN_SCOPE_SEND_PERMISSIONS,
    scope: GOOGLE_LOGIN_SCOPE_SEND_PERMISSIONS,
    flow: GOOGLE_LOGIN_FLOW,
    onSuccess: successHandler,
    onError: errorHandler,
  });

  return (
    <OutreachInitializationButton onClick={ googleLogin }>
      <GoogleLogo />
      {translate(STRING_KEYS.CONTINUE_WITH_GOOGLE)}
    </OutreachInitializationButton>
  );
};
